(function () {
  const tab1 = `<div class="popup-left">
                <div class="tab-desc">
                    <div class="title">核心产品</div>
                    <div class="desc">
                        <p>结合前沿科技及行业洞察</p>
                        <p>持续输出创新的产品与服务</p>
                    </div>
                </div>
            </div>
            <div class="popup-right">
                <div class="list-wrap">
                    <div class="list-item">
                        <div class="list-line bold">
                            <a href="/e-spread/">E推广</a>
                            <span class="icon"></span>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="list-line bold">
                            <a href="/huaxin/">花信直播平台</a>
                            <span class="icon"></span>
                        </div>
                    </div>
                </div>
            </div>`

  const tab2 = `<div class="popup-left">
                <div class="tab-desc">
                    <div class="title">解决方案</div>
                    <div class="desc">
                        <p>助力更多金融等行业客户</p>
                        <p>实现数字化升级</p>
                    </div>
                </div>
            </div>
            <div class="popup-right">
                <div class="list-wrap">
                    <div class="list-item">
                        <div class="title">直播解决方案</div>
                        <div class="list-line">
                            <a href="/huaxin/">花信直播平台</a>
                            <span class="icon"></span>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="title">企微解决方案</div>
                        <div class="list-line">
                            <a href="/hive/">蜂巢企微服务平台</a>
                            <span class="icon"></span>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="title">数字人解决方案</div>
                        <div class="list-line">
                            <a href="/digital/">数字主播系统</a>
                            <span class="icon"></span>
                        </div>
                    </div>

                </div>
                <div class="list-wrap">
                    <div class="list-item">
                        <div class="title">智能内容创造平台</div>
                        <div class="list-line">
                            <a href="/v-monitor/">爆款短视频</a>
                            <span class="icon"></span>
                        </div>
                        <div class="list-line">
                            <a href="/hot-monitor/">热点监测</a>
                            <span class="icon"></span>
                        </div>

                    </div>
                    <div class="list-item">
                        <div class="title">智能获客解决方案</div>
                        <div class="list-line">
                            <a href="/e-spread/">E推广</a>
                            <span class="icon"></span>
                        </div>
                    </div>
                </div>
                <div class="list-wrap">
                    <div class="list-item">
                        <div class="title">数据治理解决方案</div>
                        <div class="list-line">
                            <a href="/data-center/">数据中台解决方案</a>
                            <span class="icon"></span>
                        </div>
                    </div>
                </div>
            </div>`

  const tabProduct = document.querySelector('.tab-product')
  const tabResolve = document.querySelector('.tab-resolve')
  const wrap = document.querySelector('.tab-popup')
  const insertTabs = function (type) {
    const tabs = type === 1 ? tab1 : tab2

    wrap.innerHTML = tabs
    wrap.style.display = 'block'
  }
  let isInTabs = false
  let timer = 0
  tabProduct.addEventListener('mouseenter', function () {
    clearTimeout(timer)
    insertTabs(1)
  })
  tabResolve.addEventListener('mouseenter', function () {
    clearTimeout(timer)
    insertTabs(2)
  })
  let leaveTab = function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (!isInTabs) {
        wrap.style.display = 'none'
      }
    }, 300)
  }
  tabProduct.addEventListener('mouseleave', function () {
    leaveTab()
  })
  tabResolve.addEventListener('mouseleave', function () {
    leaveTab()
  })
  wrap.addEventListener('mouseenter', function () {
    clearTimeout(timer)
    isInTabs = true
  })
  wrap.addEventListener('mouseleave', function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      isInTabs = false
      wrap.style.display = 'none'
    }, 300)
  })

  /**
   * <div class="qrcode-wrap"></div>
   * */
  const layer = `
        <div class="close"></div>
        <div class="title">感谢关注濯锦科技</div>
        <div class="qrcode"></div>
        <div class="desc">请在公众号留下您的信息，我们会尽快与您联系</div>
    `

  const btnQrs = document.querySelectorAll('.btn-qr')
  btnQrs.forEach(t => {
    t.onclick = function () {
      insertQr()
    }
  })
  const insertQr = function () {
    const qr = document.querySelector('.qrcode-wrap')
    if (qr) {
      qr.style.display = 'block'
    } else {
      const el = document.createElement('div')
      el.className = 'qrcode-wrap'
      el.innerHTML = layer
      document.querySelector('#container').appendChild(el)

      // 添加进去后给btn-close添加事件
      setTimeout(() => {
        const close = document.querySelector('.qrcode-wrap .close')
        close.onclick = function () {
          el.style.display = 'none'
        }
      }, 0)
    }
  }
})()
